import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { IGlobalSettings } from 'src/app/model/common';

@Component({
    selector: "home-template",
    templateUrl: './template.component.html'
})
export class TemplateComponent implements OnInit{
    model: any = {};

    constructor(
        private _router: Router,
        private route:ActivatedRoute,
       
    ) {
        
    }


    ngOnInit() 
    {
        const globalSettings: IGlobalSettings = JSON.parse(sessionStorage.getItem('GlobalSettings')!);
        if(globalSettings==null)
        this._router.navigate(['/enrollment/login']);
        else 
        this._router.navigate(['/home']);
    }

    
}
