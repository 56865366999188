import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';



@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {

    constructor(private injector: Injector) { }

    public handleError(error: any): void {
        console.error(error); // TODO: keep during DEV, remove for other env
        const router = this.injector.get(Router);

        try {
            if (error && error.message) {
                //this.customModalService.open('ErrorComponent', { error: error.message });
            }
        } catch (err) {
            console.error(err);
        }
    }

}
