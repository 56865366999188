import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TemplateComponent } from './components/common/template/template.component';
import { AuthGuard } from './guards/auth.guard';
import { ProcessGuard } from './guards/process.guard';

const routes: Routes = [
  {
    path: '',
    component:TemplateComponent
  },
  {
    path: 'home',
    loadChildren: () => import('./components/home/home.module').then(m => m.HomeModule),
   canActivate: [ProcessGuard,AuthGuard],
   },
  {
    path: 'resources',
    loadChildren: () => import('./components/home/resources/resources.module').then(m => m.ResourcesModule),
    canActivate: [ProcessGuard],

  },
  {
    path: 'enrollment',
    loadChildren: () => import('./components/enrollment/enrollment.module').then(m => m.EnrollmentModule),
    canActivate: [ProcessGuard],

  },
  {
    path: 'user',
    loadChildren: () => import('./components/user/user.module').then(m => m.UserModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ scrollPositionRestoration : 'enabled', anchorScrolling: 'enabled', onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
