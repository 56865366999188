export const environment = {
  production: true,
  oktaUrl: '##OktaUrl##',
  oktaClientId: '##OktaClientId##',
  serviceUrl: '##ServiceUrl##',
  cdnUrl: '##CdnUrl##',
  recaptchaSiteKey:'##RecaptchaSiteKey##',
  recaptcha:'##Recaptcha##',
  applicationInsights:"##ApplicationInsights##",
  matomoUrl: '##MatomoURL##',
  matomoId: '##MatomoId##',
  idleSessionLimitInMinutes: 15
};
