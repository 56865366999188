import { Injectable } from '@angular/core';
import { IGlobalSettings, ILabels } from 'src/app/model/common';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class LabelService {
  private _labels: ILabels[] = [];
  private _programLabels: ILabels[] = [];

  constructor(private common: CommonService) {
    let sessionData: any;
    this.getLabelData();
  }

  /**
   * Get Label Data from the Program Service API.
   */
  getLabelData(): void {
    this.common.getGlobal().subscribe(data => {
      const settings: IGlobalSettings = data as any;
      this._labels = settings.BrandingLabels;
      this._programLabels = settings.Programs?.filter((y:any)=> y.ProgramId == this.common.getProgramId())[0].ProgramLabels;
    });
  }

  get labels(): ILabels[] {
    return this._labels;
  }

  set labels(data: ILabels[]) {
    this._labels = data;
  }

  /**
   * Get all labels for the given component.
   * @param {string} componentName Name of component as string value, corresponding to DB value.
   * @returns {Map<string, string>} Map of fields and labels.
   */
  getComponentLabels(componentName: string): Map<string, string> {
    const labels: Map<string, string> = new Map<string, string>();
    for (const label of this._labels) {
      if (label.Form.toLowerCase() === componentName.toLowerCase()) {
        labels.set(label.Field, label.Label);
      }
    }
    return labels;
  }

  /**
   * Get labels for multiple components.
   * @param {string[]} componentNames Array of component names.
   * @returns {Map<string, string>} Map of Field and Label values.
   */
  getMultipleComponentLabels(componentNames: string[]): Map<string, string> {
    const labels: Map<string, string> = new Map<string, string>();
    for (const label of this._labels) {
      if (componentNames.includes(label.Form)) {
        labels.set(label.Field, label.Label);
      }
    }
    return labels;
  }

  /**
   * Get program labels for a component.
   * @param {string} componentName Name of component.
   * @returns {Map<string, string>} Map of Field and Label values.
   */
  getComponentProgramLabels(componentName: string): Map<string,string> {
    const programLabels: Map<string, string> = new Map<string, string>();
    for (const label of this._programLabels) {
      if (label.Form.toLowerCase() === componentName.toLowerCase()) {
        programLabels.set(label.Field, label.Label);
      }
    }
    return programLabels;
  }

  /**
   * Get program labels for multiple components.
   * @param {string[]} componentNames Array of component names.
   * @returns {Map<string, string>} Map of Field and Label values.
   */
  getMultipleComponentProgramLabels(componentNames: string[]): Map<string, string> {
    const componentsProgramLabels: Map<string, string> = new Map<string, string>();
    for (const programLabel of this._programLabels) {
      if (componentNames.includes(programLabel.Form)) {
        componentsProgramLabels.set(programLabel.Field, programLabel.Label);
      }
    }
    return componentsProgramLabels;
  }
}
