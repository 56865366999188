import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse, } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthenticationService } from '../services/common/authentication.service';
import { catchError, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { SpinnerService } from '../components/common/spinner/spinner.service';
import { HttpProgressState } from '../model/common';

@Injectable()
export class EPlatformInterceptor implements HttpInterceptor {

  private totalRequests = 0;

  constructor(
    private authenticationService: AuthenticationService,
    private spinnerService: SpinnerService,
    private router: Router
  ) { }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> 
  {
    let token = JSON.parse(localStorage.getItem('okta-token-storage')!);
    let sessionToken = sessionStorage.getItem("SessionToken")! == null ? '' : sessionStorage.getItem("SessionToken")!;

    if (token != null)
    {
      if(token.idToken)
      {
       token = token.idToken.value;
      }
    }
    let duplicate: HttpRequest<any>;

    if (token !=null) {
      duplicate = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + token)
                            .set('SessionToken', sessionToken)
      });
    } else {
      duplicate = req.clone();
    }

    this.totalRequests++;

    this.spinnerService.setState({
      url: req.url,
      state: HttpProgressState.start
    });

    return next.handle(duplicate).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            const cleanUrl = req.url.replace(/([^:]\/)\/+/g, '$1');
            const found = cleanUrl.match(/https:\/\/[a-zA-Z0-9]+\.cardinalhealth\.com\/api\/v1\/authn/g);

            // Skip route to login, if 401 comes from Okta
            if (!found) {
              this.authenticationService.logout();
              this.router.navigate(['/enrollment/login']);
            }
          }
        }

        return throwError(error);
      }),
      finalize(() => {
        this.totalRequests--;
       

        if (this.totalRequests === 0) {
          this.spinnerService.setState({
            url: req.url,
            state: HttpProgressState.end
          });
        }
      })
    );
  }

}
