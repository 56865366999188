// TODO: update classes to interfaces and align with APIs.
export class UserPrograms {
  programs: Programs[] = [];
  user: User = new User();
}

export class UserProgramsResponse {
  programs!: string[];
  user!: User;
  statuses!: string[];
  insurances!: string[];
  constructor(data?: any) {
    Object.assign(this, data);
  }
}

export class User {
  email!: string;
  firstName!: string;
  lastName!: string;
  phoneNumber!: string;
}

export class Programs {
  ProgramId!: number;
  ProgramName!: string;
  PatientInfo!: PatientInfo;
}

export class PatientInfo {
  code!: string;
  status!: string;
  healthCareProvider!: HealthCareProvider;
  patient!: Patient;
  address!: PatientAddress[];
  contact!: any[];
  caseInfo!: CaseInfo[];
  productInfo!: ProductInfo[];
  authorization!: Authorization[];
  healthPlan!: HealthPlan[];
  triage!: any[];
  dispense!: any[];
  patientStatus!: PatientStatus[];
  statuses!: string[];
  insurances!: string[];
}


export class HealthCareProvider {
  primaryAddress!: HCProviderDetails[];
  lastName!: string;
  firstName!: string;
  NPI!: string;
}

export class HCProviderDetails {
  patientAddressLine2!: string;
  patientAddressLine1!: string;
  patientCity!: string;
  patientState!: string;
  patientZipCode!: string;
  patientCountry!: string;
  patientPrimaryAddress!: string;
}

export class Patient {
  patientFax!: string;
  patientWorkNum!: string;
  patientHomeNum!: string;
  patientCellNum!: string;
  patientFirstName!: string;
  patientLastName!: string;
  patientPhone!: string;
  patientEmail!: string;
  patientMiddleName!: string;
  patientDOB!: string;
  patientGender!: string;
  patientID!: string;
}

export class PatientAddress {
  patientAddressLine2!: string;
  patientAddressLine1!: string;
  patientCity!: string;
  patientState!: string;
  patientZipCode!: string;
  patientCountry!: string;
  patientPrimaryAddress!: boolean
}

export class CaseInfo {
  preferredPharmacyNPI!: string;
  preferredPharmacy!: string;
  patientId!: string;
  programProductId!: string;
  programCaseId!: string;
  caseId!: string;
  patientProgramNumber!: string;
  programCaseNo!: string;
  caseNo!: string;
  type!: string;
  status!: string;
  reason!: string;
  subreason!: string;
  startDate!: string;
  endDate!: string;
  caseLastModified!: string;
  copayDocumentId!: string;
  notes!: string;
  caseCreatedDate!: string;
}

export class ProductInfo {
  typeOfEnrollment!: string;
  Product!: string;
  ICDCode!: string;
  programProductId!: string;
  indicationOther!: string;
  detailedIndication!: string;
  ICDShortDescription!: string;
  NDC!: string;
  reason!: string;
  status!: string;
  active!: boolean;
  inactiveDate!: string;
  activeDate!: string;
  patientApplicationReceived !:boolean;
}

export class Authorization {
  authorizationId!: string;
  name!: string;
  type!: string;
  source!: string;
  status!: string;
  authExpirationDate!: string;
  authorizationDate!: string;
  active!: boolean;
  methodOfCommunication! : string;
}

export class HealthPlan {
  healthPlanId!: string;
  name!: string;
  payer!: string;
  effectiveDate!: string;
  expirationDate!: string;
  status!: string;
  source!: string;
  policyId!: string;
  groupNo!: string;
  rxbin!: string;
  rxpcn!: string;
  planOwner!: string;
  healthPlanRecordType!: string;
  healthPlanType!: string;
  relationshipToInsured!: string;
  insuranceSubType!: string;
  insuranceType!: string;
}

export class PatientStatus {
  name!: string;
  accountId!: string;
  caseId!: string;
  triageId!: string;
  tempHirearchy!: string;
  mainHirearchy!: number;
  statusDescription!: PatientStatusDescription;
}

export class PatientStatusDescription {
  active!: boolean;
  engagementProgram!: string;
  engagementProduct!: string;
  messageCode!: string;
  messageDescription!: string;
}

export class Userinfo {
  info!: Patient;
  address!: PatientAddress;
  prescriber!: HealthCareProvider;
  cases!: CaseInfo[];
  productInfo!: ProductInfo[];
  history!: string[];
  insurance!: string[];
  userName!: string;
}

export interface EffectiveDate {
  startDate: Date;
  endDate: Date;
  show: boolean;
}

export interface CaseEffectiveDate {
  type: string,
  status: string,
  startDate: Date,
  endDate: Date
}

export interface ProductDetails {
  reEnrollment: boolean,
  programProductId: string,
  NDC: string,
  enrollmentType: string
}
export interface Triage {
  triageId: string,
  name: string,
  caseRecordType: string,
  pharmacyName: string,
  product: string,
  status: string,
  reason: string,
  secondaryStatus: string,
  triageDate: string,
  triageCreatedDate: string
}

export interface Dispense {
  name: string,
  triageName: string,
  caseId: string,
  pharmacyName: string,
  caseRecordType: string,
  product: string,
  dispenseDate: string,
  quantity: number,
  daysofSupply: number,
  dateofService: string,
  refillsRemaining: boolean,
  trackingNumber: string,
  shippedDate: string
}

export interface TriageDispense {
  triage: Triage[],
  dispense: Dispense[]
}
