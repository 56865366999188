import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/common/authentication.service'
import { environment } from 'src/environments/environment';
import { VersionCheckService } from 'src/app/services/common/version-check.service';
import { IGlobalSettings } from '../model/common';
import { ILocStrgUser } from '../model/user';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {

    constructor(private router: Router,private authenticateservice: AuthenticationService,
        private versionCheck: VersionCheckService) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const authenticated = await this.authenticateservice.isAuthenticated();
        if (authenticated) 
        {
            
            const user: ILocStrgUser = JSON.parse(sessionStorage.getItem('user')!);
            const globalSettings: IGlobalSettings = JSON.parse(sessionStorage.getItem('GlobalSettings')!);
            if(user==null || globalSettings ==null)
            this.authenticateservice.login();
            if(globalSettings.BrandingId == user.brandingId)
            return true;
            else 
            this.authenticateservice.login();
        }
    
        // Redirect to login flow.
        this.authenticateservice.login();
        return false;

    }
}