import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import 'rxjs/add/observable/throw';



export abstract class GenericCrudService<T> extends BehaviorSubject<any> {
  constructor(
    protected http: HttpClient,
    protected url:any,
    protected oktaURL: string = '',
  ) {
    super({});
  }

 
  public getAll(method: string = ''): Observable<T[]> {
    return this.http.get(this.getUrl(method)).pipe(
      map((res:any) => {
        return res;
      }),
      catchError(this.handleError<T>(`getAll`))
    ) as Observable<T[]>;
  }

  public get(id: string, method: string = ''): Observable<T> {
    return this.http.get(`${this.getUrl(method)}/${this.encodeParameter(id)}`).pipe(
      map((res:any) => {
        return res;
      }),
      catchError(this.handleError<T>(`get id=${this.encodeParameter(id)}`))
    ) as Observable<T>;
  }

  public read<T>(objToCreate: T, method: string = ''): Observable<T> {
    return this.http.post<T>(`${this.getUrl(method)}`, objToCreate).pipe(
      map((res) => {
        return res;
      }),
      catchError(this.handleError<T>('read'))
    ) as Observable<T>;
  }

  public create<T>(objToCreate: T, method: string = ''): Observable<T> {
    return this.http.post<T>(`${this.getUrl(method)}`, objToCreate).pipe(
      map((res) => {
        return res;
      }),
      catchError(this.handleError<T>('create'))
    ) as Observable<T>;
  }

  public GetUser<T>(objToCreate: T, method: string = ''): Observable<T> {
    return this.http.post<T>(`${this.getUrl(method)}`, objToCreate).pipe(
      map((res) => {
        return res;
      }),
      catchError(this.handleError<T>('GetUser'))
    ) as Observable<T>;
  }

  public createAuth<T>(
    objToCreate: T,
    method: string = '',
    token: string = ''
  ): Observable<T> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Authorization', `Bearer ${token}`);

    return this.http
      .post<T>(`${this.getUrl(method)}`, objToCreate, { headers: headers })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError(this.handleError<T>('create'))
      ) as Observable<T>;
  }

  public post<T>(objToCreate: T, method: string = ''): Observable<T> {
    return this.http.post<T>(`${this.getUrl(method)}`, objToCreate).pipe(
      map((res) => {
        return res;
      }),
      catchError(this.handleError<T>('create'))
    ) as Observable<T>;
  }

  public postFiles<T>(objToCreate: T, method: string = ''): Observable<T> {
    return this.http.post<T>(`${this.getUrl(method)}`, objToCreate,{reportProgress: true, observe: 'events'}).pipe(
      map((res) => {
        return res;
      }),
      catchError(this.handleError<T>('create'))
    ) as Observable<T>;
  }

  public patch<T>(objToCreate: T, method: string = ''): Observable<T> {
    return this.http.patch<T>(`${this.getUrl(method)}`, objToCreate).pipe(
      map((res) => {
        return res;
      }),
      catchError(this.handleError<T>('create'))
    ) as Observable<T>;
  }

  public update<T>(
    id: string,
    objToUpdate: T,
    method: string = ''
  ): Observable<T> {
    return this.http.put<T>(`${this.getUrl(method)}${this.encodeParameter(id)}`, objToUpdate).pipe(
      map((res) => {
        return res;
      }),
      catchError(this.handleError<T>('update'))
    ) as Observable<T>;
  }

  public delete<T>(id: string): Observable<T> {
    return this.http.delete<T>(`${this.url}/${this.encodeParameter(id)}`).pipe(
      map((res) => {
        return res;
      }),
      catchError(this.handleError<T>('delete'))
    );
  }

  public getUrl(method: string): string {
    return method === '' ? encodeURI(this.url) : encodeURI(`${this.url}/${method}`);
  }

  public getOktaUrl(method: string): string {
    return method === '' ? encodeURI(this.oktaURL) : encodeURI(`${this.oktaURL}/${method}`);
  }

  public encodeParameter(parameter: string) {
    return parameter === '' ? '' : encodeURI(parameter);
  }

  public createOKTA<T>(objToCreate: T, method: string = ''): Observable<T> {
    return this.http.post<T>(`${this.getUrl(method)}`, objToCreate).pipe(
      map((res) => {
        return res;
      }),
      catchError(this.handleError<T>('create'))
    ) as Observable<T>;
  }

  public postOKTA<T>(objToCreate: T, method: string = ''): Observable<T> {
    return this.http.post<T>(`${this.getUrl(method)}`, objToCreate).pipe(
      map((res) => {
        return res;
      })
    ) as Observable<T>;
  }

  public updateOKTA<T>(
    id: string,
    objToUpdate: T,
    method: string = ''
  ): Observable<T> {
    return this.http.put<T>(`${this.getUrl(method)}${this.encodeParameter(id)}`, objToUpdate).pipe(
      map((res) => {
        return res;
      }),
      catchError(this.handleError<T>('update'))
    ) as Observable<T>;
  }

  public log(message: string) { }

  public handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      this.log(`${operation} failed: ${error.message}`);
      return Observable.throw(error.message || "server error.");
    };
  }

}
