import { Injectable } from '@angular/core';
import { GenericCrudService } from './generic-crud.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { IGlobalSettings, IProgramDefaults } from 'src/app/model/common';

@Injectable({
  providedIn: 'root'
})
export class CommonService extends GenericCrudService<any>
{
  public globalSettings: any;
  private patternStyleLoading = new BehaviorSubject<any>({} as any);
  public patternStyleLoading$ = this.patternStyleLoading.asObservable();
  SelectedProgramType: any;
  public serviceNames: any ={pap: 'PAP' , hap: 'HAP',reEnrollment: 'Re-Enrollment', bi:'BI',
  papReEnrollment: 'PAP Re-Enrollment',hapReEnrollment: 'HAP Re-Enrollment', copay: 'COPAY',enrollment: 'Enrollment',
  hipaa: 'HIPAA', marketingHippa:'MarketingHIPAA', marketing: 'Marketing', income: 'Electronic Income', copayEnrollment: 'Copay Only', injectionEducation: 'Injection Education'};
  public isReEnrollmentAlertShow: boolean = false;
  public isMissingInfoAlertShown: boolean = false;
  constructor(http: HttpClient) {
    super(http, environment.serviceUrl);
  }

  private triggerStyleLoading(trigger: any) {
    this.patternStyleLoading.next(trigger);
  }

  public getManufacturerName() {
    this.globalSettings = sessionStorage.getItem('GlobalSettings')
    const obj = JSON.parse(this.globalSettings);
    if (this.globalSettings == null)
      window.location.href = '/';
    else
      return obj.ManufacturerName;
  }

  public getProgramName() {
    if (this.globalSettings == null || this.globalSettings.length < 1) {
      return '';
    }
    var obj: any
    if (!JSON.parse(sessionStorage.getItem('userProgramSelection')!)) {
      obj = JSON.parse(this.globalSettings)
      return obj.Programs[0].ProgramName
    }

    obj = JSON.parse(sessionStorage.getItem('userProgramSelection')!)
    return obj.programShortName;
  }

  public getProgramShortName(){
    if (this.globalSettings == null || this.globalSettings.length < 1) {
      return '';
    }

    const obj = JSON.parse(this.globalSettings);
    return obj.Programs[0].ProgramShortName;
    
  }
  public getProgramId() {
    var programId: number = 0;
    if (sessionStorage.getItem('GlobalSettings') != null) {
      const globalSettings: IGlobalSettings = JSON.parse(sessionStorage.getItem('GlobalSettings')!);
      if (globalSettings.Programs[0].ProgramDefaults instanceof Array) {
        programId = Number(globalSettings.Programs[0].ProgramId);
      }
    }
    if (JSON.parse(sessionStorage.getItem('userProgramSelection')!)) {
      let obj = JSON.parse(sessionStorage.getItem('userProgramSelection')!)
      programId = obj.programId;
    }
    return programId
  }
  public getPrograms() {
    var brandingPrograms:any=[];
    if (sessionStorage.getItem('GlobalSettings') != null) 
    {
      const globalSettings: IGlobalSettings = JSON.parse(sessionStorage.getItem('GlobalSettings')!);
      if (globalSettings.Programs instanceof Array) 
      {
        globalSettings.Programs.forEach(y=> {
          brandingPrograms.push({ProgramId: y.ProgramId,ProgramShortName: y.ProgramShortName, ProgramName : y.ProgramName});
        })

        return brandingPrograms;
      }
    }
    return [];
  }

 

  public getBrandingId() {
    const obj = JSON.parse(this.globalSettings);
    return obj.BrandingId;
  }

  public getBrandingName() {
    const obj = JSON.parse(this.globalSettings);
    return obj.BrandingName;
  }

  public getGlobal() {
    return this.getAll(`api/Branding/Get?baseUrl=` + window.location.origin)
  }
  public getGlobalData() {
    var main = this;
    return this.getAll(`api/Branding/Get?baseUrl=` + window.location.origin).subscribe(function (res) {
      if (res) {
        sessionStorage.setItem("GlobalSettings", JSON.stringify(res));
        main.triggerStyleLoading('triggered')
      }
    });
  }
public getProgramDefaults(fieldValue: string){
  if (sessionStorage.getItem('GlobalSettings') != null) {
    const globalSettings: IGlobalSettings = JSON.parse(sessionStorage.getItem('GlobalSettings')!);
    if (globalSettings.Programs[0].ProgramDefaults instanceof Array) {
      var docTypes: IProgramDefaults[] = []
      globalSettings.Programs.forEach(e => {
        docTypes = e.ProgramDefaults.filter(x => x.Field == fieldValue);
      })
      if (docTypes) {
        return JSON.parse(docTypes[0].Value);

      }
    }
  }
}
  public setLibraryFiles(document: any): void {
    {
      const head = document.getElementsByTagName('head')[0];
      const url = encodeURI(environment.cdnUrl + '/content/Programs/' + this.getManufacturerName() + '/' + this.getProgramName() + '/css/styles.css');

      const themeLink = document.getElementById(
        'client-theme'
      ) as HTMLLinkElement;

      if (!themeLink) {
        const style = document.createElement('link');
        style.id = 'client-theme';
        style.rel = 'stylesheet';
        style.href = `${url}`;
        head.appendChild(style);
      }
    }
  }

  public getLibraryFilePath() {
    return environment.cdnUrl + '/content/Programs/' + this.getManufacturerName() + '/' + this.getProgramName();
  }

  public getResourceFilePath(): string {
    return environment.cdnUrl + '/resource/Programs/' + this.getManufacturerName() + '/' + this.getProgramName();
  }

  public setProgramType(ProgramType: any){
    this.SelectedProgramType = ProgramType;
  }

  public getProgramType(){
    return this.SelectedProgramType;
  }

  public fileUpload(model: any,programCaseId:string,programProductId:string,categoryName:string,programId:Number, documentType: string, task: boolean ) {
    return this.postFiles(model, 'api/patient/upload?programCaseId=' + programCaseId + '&programProductId=' + programProductId + '&categoryName=' + categoryName + '&programId='+ programId +'&documentType='+documentType + '&task='+task);
  }

}
