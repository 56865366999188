import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IHttpState } from 'src/app/model/common';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  private state = new BehaviorSubject<IHttpState>({} as IHttpState);

  public state$ = this.state.asObservable();

  constructor() { }

  public setState(httpState: IHttpState) {
    this.state.next(httpState);
  }
}
