import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { GenericCrudService } from './common/generic-crud.service';
import { UserPrograms, UserProgramsResponse, Programs, PatientInfo, ProductDetails } from '../model/userprograms';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from './common/common.service';
@Injectable({
  providedIn: 'root'
})
export class UserProgramsService extends GenericCrudService<any> {
  userprograms: UserPrograms = new UserPrograms();
  constructor(http: HttpClient, private router: Router, private route: ActivatedRoute,
    private commonService: CommonService) {
    super(http, environment.serviceUrl);
  }

  public getUserSelectedPrograms(userPrograms: any) {
    
    var userProgramSelection:any=[];
    var brandingPrograms = this.commonService.getPrograms();
    brandingPrograms.forEach((y:any)=> {
      var program = userPrograms.filter((k:any) => k.ProgramId == y.ProgramId);
      if(program.length>0)
      userProgramSelection.push({ 'programId':program[0].ProgramId, 'programShortName': program[0].ProgramShortName, 'programName': y.ProgramName });
    } )
    return userProgramSelection;
  }

  public setUserProgramSelection(programId:string, programShortName:string) {
    sessionStorage.setItem("userProgramSelection", JSON.stringify({ 'programId':programId, 'programShortName': programShortName }))
  }

  public getUserProgramSelection() {
    if(sessionStorage.getItem("userProgramSelection")!=null)
    return true;
    else 
    return false; 
  }

  public getUserPrograms() {
    return this.userprograms;
  }

  public setUserPrograms(userPrograms: UserProgramsResponse)
  {
    this.userprograms.programs = [];
    this.userprograms.user = userPrograms.user;
    userPrograms.programs.forEach((ele : string) => {
      this.userprograms.programs.push(JSON.parse(ele))
    });

    this.userprograms.programs.forEach(y=> {

      userPrograms.statuses.forEach(x=>
        {
          if(y.ProgramId == JSON.parse(x).ProgramId)
          {
            y.PatientInfo.statuses = [ x ];
          }

        });

        userPrograms.insurances.forEach(k=>
          {
            if(y.ProgramId == JSON.parse(k).ProgramId)
            {
              y.PatientInfo.insurances = [k];
            }
  
          });
  
    });
    //this.userprograms.programs[0].PatientInfo.statuses = userPrograms.statuses;
    //this.userprograms.programs[0].PatientInfo.insurances = userPrograms.insurances;
  }

  public getPatientInfo():PatientInfo {
      let program: Programs[] = []
      this.userprograms.programs.forEach((x: Programs) => {
        if(x.ProgramId == this.commonService.getProgramId()){
          program.push(x)
        }
      });

      if(program.length == 0){
        return new PatientInfo();
      } else {
        return program[0].PatientInfo
      }
  }

  /**
   * Determine if patient has a copay.
   * @returns {boolean} True if patient has copay.
   */
  public hasCopay(): boolean {
    const productEnrollment: boolean = this.getPatientInfo().productInfo.some(info => info.typeOfEnrollment.toLowerCase() === 'copayenrollment');
    const cases: boolean = this.getPatientInfo().caseInfo.some(info => info.type.toLowerCase() === 'copay');
    return productEnrollment || cases;
  }

  public getProductInfo(tracker:boolean=false): ProductDetails {
    let model = {
      reEnrollment: false,
      programProductId: "",
      NDC: "",
      enrollmentType: ""
    }
    let patientInfo = this.getPatientInfo();
    if(patientInfo && patientInfo.caseInfo.length > 0)
    {
      let productInfo = patientInfo.productInfo.sort((a:any, b:any) => (a.activeDate > b.activeDate ? -1 : 1));
      for(let info of productInfo){
        if ((info.typeOfEnrollment == this.commonService.serviceNames.reEnrollment ||
          info.typeOfEnrollment == this.commonService.serviceNames.papReEnrollment ||
          info.typeOfEnrollment == this.commonService.serviceNames.hapReEnrollment)) {
          model.programProductId = info.programProductId;
          model.reEnrollment = true;
          model.enrollmentType = "reEnrollment";
          model.NDC = info.NDC
          break;
        }
        else if (info.typeOfEnrollment == this.commonService.serviceNames.enrollment && model.reEnrollment==false) {
          model.programProductId = info.programProductId;
          model.NDC = info.NDC
          model.enrollmentType = "enrollment";
          break;
        }
        else if(info.typeOfEnrollment == this.commonService.serviceNames.copayEnrollment){
          model.programProductId = info.programProductId;
          model.NDC = info.NDC
          model.enrollmentType = "copay";
          break;
        }
      }
    }
    return model
  }

  public checkProductInfo(){
    let patientInfo = this.getPatientInfo();
    if(patientInfo && patientInfo.caseInfo.length > 0)
    {
      let productInfo = patientInfo.productInfo.sort((a:any, b:any) => (a.activeDate > b.activeDate ? -1 : 1));
      for(let info of productInfo){
        if ((info.typeOfEnrollment == this.commonService.serviceNames.reEnrollment ||
          info.typeOfEnrollment == this.commonService.serviceNames.papReEnrollment ||
          info.typeOfEnrollment == this.commonService.serviceNames.hapReEnrollment) && info.patientApplicationReceived==false) {
          return true;
        }
        else if (info.typeOfEnrollment == this.commonService.serviceNames.enrollment && info.active == true ) {
          return true;
        }
        else if(info.typeOfEnrollment == this.commonService.serviceNames.copayEnrollment && info.active == true){
          return true;
        }
      }
    }
    return false;
  }
}
