import { Injectable } from '@angular/core';
import {Idle, DEFAULT_INTERRUPTSOURCES} from '@ng-idle/core';
import { AuthenticationService } from '../common/authentication.service';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable()
export class UserTimeoutService {
    timeUnit: number = 60;

    constructor(private idleMonitor: Idle,
        private _authenticationService: AuthenticationService,
        private router: Router) {
        // sets an idle timeout in seconds.
        idleMonitor.setIdle(environment.idleSessionLimitInMinutes * this.timeUnit);

        // sets a timeout period in seconds.
        idleMonitor.setTimeout(5);

        // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
        idleMonitor.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        idleMonitor.onTimeoutWarning.subscribe((counter:any) => {
            console.error('Your session will terminate in ' + counter + ' seconds.')
        });

        idleMonitor.onTimeout.subscribe(() => {
            _authenticationService.isAuthenticated().then(res => {
                if (res) {                  
                     _authenticationService.logout();
                    // console.error('Your session has ended. You are being logged off.')
                    // router.navigate(["/logout"]);
                }
            })
        });
    }

    startMonitor(): void {
        this.idleMonitor.watch();
    }
}
