
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { Router } from '@angular/router';
import { OktaAuth } from '@okta/okta-auth-js';
import { environment } from '../../../environments/environment';
import { MatomoTracker } from '@ngx-matomo/tracker';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {


  $isAuthenticated: Observable<boolean>;
  private observer!: Observer<boolean>;
  _oktaAuth = new OktaAuth({
    issuer: environment.oktaUrl,
    clientId: environment.oktaClientId,
    redirectUri: window.location.origin
  });
  constructor(private router: Router,
    private matomoTracker: MatomoTracker
    ) {
    this.$isAuthenticated = new Observable((observer: Observer<boolean>) => {
      this.observer = observer;
      this.isAuthenticated().then(val => {
        observer.next(val);
      });
    });
  }

  login() {
    sessionStorage.removeItem("SessionToken");
    this.router.navigate(['/enrollment/login']);
  }

  async generateAuthToken(sessionId: string, url: string) {
    const tokenContainer = await this._oktaAuth.token.getWithoutPrompt({
      responseType: ['id_token'],
      sessionToken: sessionId,
      scopes: ['openid', 'email', 'phone', 'address', 'groups', 'profile']
    });
    sessionStorage.setItem('idToken', tokenContainer.tokens.idToken?.idToken!);
    this._oktaAuth.tokenManager.add('idToken', tokenContainer.tokens.idToken!);
    this._oktaAuth.tokenManager.add('accessToken', tokenContainer.tokens.accessToken!);
    this.router.navigateByUrl(url!);
  }

  async getAuthToken() {
    if (await this._oktaAuth.tokenManager.get('idToken')) {
      const tokenKey = this._oktaAuth.tokenManager.get('idToken').valueOf();
      if (tokenKey) {
        return tokenKey.toString();
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  async isAuthenticated() {
    const sessionContainer = await this._oktaAuth.session.get();
    if (sessionContainer.status == 'INACTIVE')
      return false;
    else
      return !!(await this._oktaAuth.tokenManager.get('accessToken'));
  }

  async logout() {
    const user = sessionStorage.getItem('user');
    if (JSON.parse(user!) != null) {
      this.matomoTracker.resetUserId();
    }

    sessionStorage.removeItem('user');
    sessionStorage.removeItem("SessionToken");
    localStorage.removeItem('okta-token-storage');
    localStorage.removeItem('okta-cache-storage');
    await this._oktaAuth.session.close();
    this.router.navigate(['/enrollment/login']);
  }
}


