export enum HttpProgressState {
    start,
    end
}

export interface IHttpState {
    url: string;
    state: HttpProgressState;
}

export interface IGlobalSettings {
    ProgramId: number;
    BrandingId: number;
    BrandingName: string;
    BaseUrl: string;
    LogoUrl: string;
    LogoWidth: string;
    LogoHeight: string;
    FooterLogoUrl: string;
    FooterLogoWidth: string;
    FooterLogoHeight: string;
    PrimaryColor: string;
    SecondaryColor: string;
    TermsConditions: string;
    ProgramName: string;
    ShortProgramName: string;
    ManufacturerName: string;
    ApplicationId: number;
    ApplicationName: string;
    ContactName: string;
    ContactTime: string;
    PrescribingLink: string;
    SafetyInfoLink: string;
    MedicationGuideLink: string;
    PrivacyPolicyLink: string;
    Components: IComponents[];
    BrandingLabels: ILabels[];
    Drugs: IDrugs[];
    Resources: IResources[];
    ServiceTypes: IServiceTypes[];
    Programs: IPrograms[];
    ActionTiles: IActionsTiles[];
    Diagnosis: IDiagnosis[];
    SystemStatuses: ISystemStatuses[];
    ConsentClauses: IConsentClauses[];
    PatientConsoleResults: IPatientConsoleResults[];
}

export interface IComponents {
    ComponentName: string;
    ComponentTitle: string;
    ComponentSubTitle: string;
    ComponentLinkText: string;
    ComponentLinkUrl: string;
}

export interface ILabels {
    Form: string;
    Field: string;
    Label: string;
}

export interface IDrugs {
    DrugName: string;
    NDC: string;
    DaysSupply: number;
    Quantity: number;
    DrugDescription: string;
}

export interface IResources {
    FileName: string;
    DisplayName: string;
    Description: string;
    ResourceImg: string;
    SortOrder: number;
}

export interface IServiceTypes {
    ServiceTypeId: number;
    Service: string;
    Description: string;
    ProgramServiceName?: string;
    IsPAPProgram:string;
}

export interface IPrograms {
  ProgramId: string;
  ProgramName: string;
  ProgramShortName: string;
  ProgramLabels: any[];
  ProgramDefaults: IProgramDefaults[],
  ProgramServiceTypes: IActionsTiles[],
  TokenId:number
  ProgramsNavigation: IProgramsNavigation[];

}

export interface IProgramDefaults {
    Field: string;
    Value: string;
    ApplicationName: string;
    ApplicationId: number;
    Id: number;
    IsDeleted: boolean;
    ProgramId: number;
}
export interface IProgramsNavigation {
    DisplayName: string;
    Id: number;
    Next: string;
    Prev: string;
    ProgramId: number
    Skip: string; 
    Workflow: string; 
}

export interface IActionsTiles {
//   description: string;
//   service: string;
//   //  title: string;
//     type: string;
//     order: number;
//     isReEnroll: boolean;

    Description: string;
    ProgramId: number;
    Service: string;
    ServiceShortName: string;
    SortOrder: number;
    pst: IActionsTilesPST[];
}

export interface IActionsTilesPST {
    Action: string;
}

export interface IBrandingLabel {
    Form: string;
    Field: string;
    Label: string;
}

export interface IDiagnosis {
    DiagnosisDescription: string;
    DiagnosisName: string;
    ServiceTypeId: number;
}

export interface ISystemStatuses {
    Description: string;
    SystemStatusId: number;
}

export interface IConsentClauses {
    BrandingId: number;
    Clauses: IClause[];
    ConsentId: number;
    ConsentLanguage: string;
    ConsentRequired: boolean;
    ProgramId: number;
    TypeOfConsent: string;
}

export interface IClause {
    Actions: IClauseAction[];
    ClauseRequired: boolean;
    ConsentClause: string;
    ConsentClauseId: number;
    ConsentClauseOrder: number;
}

export interface IClauseAction {
    Action: string;
}

export interface IPatientConsoleResults{
    type: string;
    title:string;
    isvisible: boolean;
}

export interface IauthCriteria {
    active: string;
    inactive: string;
    inactiveduration: string;
    source: string;
    status: string;
    type: string;
}

export interface IpapCriteria {
    Reason: string;
    Status: string;
    SubReason: string;
    reasonFlag: string;
    subreasonFlag: string;
}