import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { VersionCheckService } from 'src/app/services/common/version-check.service';
import { environment } from 'src/environments/environment';

@Injectable({providedIn: 'root'})
export class ProcessGuard implements CanActivate {
    constructor(private versionCheck: VersionCheckService) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
       this.versionCheck.checkVersion();
       
       return true;
    }
}